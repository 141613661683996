import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import Instructions from "../components/sections/instructions"
import GetStarted from "../components/sections/getstarted"





const InstructionsPage = () => (
    <Layout>
    <SEO title="Instructions" />
    <Sub_Navigation />
    <Instructions />
  </Layout>
)


export default InstructionsPage