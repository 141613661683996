import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import One from "../../images/1.png"
import Two from "../../images/2.png"
import Three from "../../images/3.png"
import Img from "../../images/img.png"
import Pic1 from "../../images/pic1.png"
import Pic2 from "../../images/pic2.png"
import Pic3 from "../../images/pic3.png"
import Pic4 from "../../images/pic4.png"
import Pic5 from "../../images/pic5.png"
import Pic6 from "../../images/pic6.png"
import Pic7 from "../../images/pic7.png"
import Pic8 from "../../images/pic8.png"
import Pic9 from "../../images/pic9.png"
import Pic10 from "../../images/pic10.png"
import Pic11 from "../../images/pic11.png"
import Pic12 from "../../images/pic12.png"
import Pic12b from "../../images/pic12b.png"
import Pic12c from "../../images/pic12c.png"
import Pic13 from "../../images/pic13.png"
import Pic13a from "../../images/pic13a.png"
import Pic13b from "../../images/pic13b.png"
import Pic14 from "../../images/pic14.png"
import Pic15 from "../../images/pic15.png"

import { Section, Container } from "../global"
import GatsbyImage from "gatsby-image"

const Instructions = () => (
  <Section id="start">
    <StyledContainer>
      <SectionTitle>3 simple steps to get you started...</SectionTitle>
      <FeaturesGrid1>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#one">
        <img src={One} alt="One" style={{width: "30%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Connect device to your home wifi</FeatureTitle>
          <FeatureText1>
            Power the device and configure credentials to connect it to your wifi network.
          </FeatureText1>
        </FeatureItem>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#two">
        <img src={Two} alt="One" style={{width: "30%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Set up your device in the Arco App.</FeatureTitle>
          <FeatureText1>
            Open the Arco App, create a user account and set up your device.
          </FeatureText1>
        </FeatureItem>
        <FeatureItem style={{textAlign: "center"}}>
        <AnchorLink  offset='150' href="#three">
        <img src={Three} alt="One" style={{width: "30%", display: "inline-block"}}/>  
        </AnchorLink>
          <FeatureTitle>Install the hardware and enjoy.</FeatureTitle>
          <FeatureText1>
            Install sensors and connect the configured device to your garage door opener.
          </FeatureText1>
        </FeatureItem>
      </FeaturesGrid1>
       <Subtitle>Please feel free to contact <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> in case you need assistance during installation.</Subtitle>
    </StyledContainer>

    <StyledContainer>
      <FeaturesGridHeader id="one">
        <FeatureItem>
        <SectionTitle>1. Connect the device to your home wifi</SectionTitle>
        </FeatureItem>
        </FeaturesGridHeader>
      <FeaturesGrid>
        <FeatureItem>
        <img src={Pic1} alt="Power_up" style={{width: "85%"}}/>  
          <FeatureTitle>Power up your device</FeatureTitle>
          <FeatureText>
            a) Connect the device to power supply.
            <br></br>
            b) The LED will blink once and then it will blink Blue intermittently until it finds a configured network.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic2} alt="Connect_to_Wifi" style={{width: "80%"}}/>  
          <FeatureTitle>Connect to the device wifi</FeatureTitle>
          <FeatureText>
            a) Using your mobile device or computer, search for the device wifi and connect to its access point: "ARCO_WEMS-XXXXX"
            <br></br>
            b) Enter the wifi password provided on the label at the back of the device.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic3} alt="Choose_Wifi" style={{width: "80%"}}/>  
          <FeatureTitle>Configure wifi credentials </FeatureTitle>
          <FeatureText>
           a) Once connected to the device wifi, a set-up page will open on our mobile device browser.
           <br></br>
           b) Look up the avaialbel Wifi networks in range by clicking the Blue "Scan Networks" button. The "WiFi Network" drop down menu will update. Select the one you want to connect to.
           <br></br>
           c) Enter its password and click the red button "Save Credentials".
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic4} alt="Save_Reboot" style={{width: "85%"}}/>  
          <FeatureTitle>Save and reboot</FeatureTitle>
          <FeatureText>
            a) After clicking the "Save Credentials" button, the device will reboot.
            <br></br>
            b) The LED will blink red once and if the device finds the configured wifi and the passord is correct, the blue LED will stay lit for a few seconds and then change to green and stay lit.
            <br></br>
            c) If LED keeps blinking, please verify the wifi credentials and repeat the previous step.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>


      <FeaturesGridHeader id="two">
        <FeatureItem>
        <SectionTitle>2. Set up your device in the Arco App</SectionTitle>
        </FeatureItem>
        </FeaturesGridHeader>
      <FeaturesGrid>
        <FeatureItem>
        <img src={Pic5} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Open the Arco App</FeatureTitle>
          <FeatureText>
            a) The Arco app is a progressive cross platform app which does not need to be downloaded from your mobile applcaiton store.
            <br></br>
            b) Browse to the web application by clicking the following <a href="https://d39nx0ykqq1lyw.cloudfront.net">link</a>.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic6} alt="Img" style={{width: "85%"}}/>  
          <FeatureTitle>Create an account</FeatureTitle>
          <FeatureText>
            a) Input the required information.
            <br></br>
            b) The user name cannot have spaces or special characters.
            <br></br>
            c) The password should be at least 8 characters and inlcude at least 1 upper case letter.
            d) Pleae accept our terms and privacy policy so the registration button becomes visible. 
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic7} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Confirm your account </FeatureTitle>
          <FeatureText>
           a) Find the verification code sent to the email address you provided in the account set up form.
           <br></br>
           b) Enter the verificaiton code in the corresponding field. 
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic8} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Sign in</FeatureTitle>
          <FeatureText>
           a) Use your credentials to sign in.
            <br></br>
           b) If you forgot your password, use the "Reset Password" link in blue to reset your password.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic9} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Add a device</FeatureTitle>
          <FeatureText>
           a) Add a device by clicking the "+" symbol at the top right corner of the screen.
            <br></br>
           b) The device registration screen will open. 
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic10} alt="Img" style={{width: "90%"}}/>  
          <FeatureTitle>Registration</FeatureTitle>
          <FeatureText>
           a) Fill in your device credentials shown on the label at the back of your device or by scanning the QOR Code.
            <br></br>
           b) To scan the bar code click the "Scan QR Code" button, place the QOR code at the center of your mobile device camera.
           <br></br>
           c) If scanned correctly, the device credentials (Device ID and Password) will auto populate.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic11} alt="Img" style={{width: "90%"}}/>  
          <FeatureTitle>Notifications</FeatureTitle>
          <FeatureText>
           a) Assign a device name if you want to replace the default device name "My Device".
            <br></br>
           b) Configure notificaitons by entering the time after which you would like the system to notify you that your garage door has been open. 
           <br></br>
           c) Select the type of notificaiton you want to receive. 
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic11} alt="Img" style={{width: "90%"}}/>  
          <FeatureTitle>Registration</FeatureTitle>
          <FeatureText>
           a) Please note that SMS type has a smaller quota due to costs of SMS messages. Email or WebHook notificaiton types are preferred. You can increase the quota of SMS by purchasing more. For details please visit www.arcoinnovaiton.com.
           <br></br>
           b) Complete registration by clicking "Register Device" button.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic12} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Test</FeatureTitle>
          <FeatureText>
           a) Click the newly added device from the list to make sure it was configured properly.
           <br></br>
           b) The device page shuold display as shown in the picture above.
            <br></br>
           c) Additional devices can be added, for example if you have two garage doors. 
          </FeatureText>
        </FeatureItem>
       
        <FeatureItem>
        <img src={Pic12b} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Configuration</FeatureTitle>
          <FeatureText>
           a) To change device configuraiton, simply slide the device item in the device list.
           <br></br>
           b) Press the device "Config" button.
            <br></br>
           c) Similarly you can delete the device by pressing the "delete" button. 
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>


      <FeaturesGridHeader id="three">
        <FeatureItem>
        <SectionTitle>3. Install hardware</SectionTitle>
        </FeatureItem>
        </FeaturesGridHeader>
      <FeaturesGrid>
        <FeatureItem>
        <img src={Pic13} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Install door sensor </FeatureTitle>
          <FeatureText>
            a) Install magnetic sensor (magnetic reed switch) at the top of the garage door frame with the hardware provided.
            <br></br>
            b) Make sure the gap between the magnet and the sesor does not exceed 1/4 IN. If necessary use the provided bracket to adjust the gap.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic13a} alt="Img" style={{width: "80%"}}/>  
          <FeatureTitle>Guide sensor cable</FeatureTitle>
          <FeatureText>
            a) Using the cable clamps, run the cable by the ceiling until you reach the garage door opener.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic13b} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Connect the control cable </FeatureTitle>
          <FeatureText>
           a) Unplug the garage door opener from the power outlet. 
           <br></br>
           b) Connect the control cable to the garage door opener auxiliary port. [WARNING: Ensure garage door opener is disconnected from the power outlet and follow the garage door opener manual to identify the auxiliary port.]
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic14} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Connect the device</FeatureTitle>
          <FeatureText>
           a) Connect the garage door opener back to the power outlet. 
            <br></br>
           b) Connect the magnetic sensor and the control cables to the device.
           <br></br>
           c) Power up the device.
           <br></br>
           d) Device LED will blink red once, then turn blue for a few seconds and then turn and stay green. 
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic15} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Test the device</FeatureTitle>
          <FeatureText>
           a) Using the Arco App open the device operation screen.
            <br></br>
           b) The door status should correspond to the status of the door (OPEN or CLOSED).
           <br></br>
           c) Test the device operation by sliding the buttton lock and clicking the Open / Close symbol in the middle of the screen.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
        <img src={Pic12c} alt="Img" style={{width: "100%"}}/>  
          <FeatureTitle>Inspect Log</FeatureTitle>
          <FeatureText>
           a) Click the device section on the device operation screen to access the device log.
           <br></br>
           b) The device log will show a summary of the door status changes in the last 7 days. Log instances will automatically delete after 7 days. 
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
      <Subtitle>Please feel free to contact <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> in case you need assistance during installation.</Subtitle>

    </StyledContainer>

  </Section>

)

export default Instructions

const StyledContainer = styled(Container)`
margin: 80px auto;`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 17px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid1 = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`

const FeaturesGrid = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
`

const FeaturesGridHeader = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  height: 50%;
  font-size: 16px;
`

const FeatureText1 = styled.p`
  height: 50%;
  font-size: 17px;
`
